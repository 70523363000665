import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        stocks: [],
        pagination: null,
        search: {
            brands: [],
            product: {
                id: "",
                barcode: "",
                name: "",
            },
            shelve_name: "",
            suppliers: [],
            status: [],
            stock_return: [],
            sorting: "DESC",
            orderBy: "created_at",
            add_user_id: [],
            approver_user_id: [],
            limit: 15,
            page: 1
        }
    },
    getters: {
        _stocks(state) {
            return state.stocks;
        },
        _search(state) {
            return state.search
        },
        _pagination(state) {
            return state.pagination
        }
    },
    mutations: {
        setStocks(state, stocks) {
            state.stocks = stocks
        },
        setPagination(state, data) {
            delete data.data;
            state.pagination = data;
        },
        editStock(state, data) {
            state.stocks.forEach(stock => {
                console.log(stock.id + '==' + data.id);
                if (stock.id == data.id) {
                    if (data.stockCard) {
                        stock.status = data.stockCard.status;
                    }
                }
            })
        },
        deleteBrand(state, key) {
            state.search.brands.splice(key, 1);
        },
    },
    actions: {
        getAll(context) {
            return axios.post('/stock/all', context.state.search)
                .then(res => {
                    context.commit('setStocks', res.data.data)
                    context.commit('setPagination', res.data)
                    return {
                        title: 'Başarılı',
                        variant: 'success',
                        icon: 'ArrowUpCircleIcon',
                    };
                })
                .catch(() => {
                    alert('stok seçkme hatası')
                    return {
                        title: 'Başarısız',
                        variant: 'danger',
                        icon: 'ArrowUpCircleIcon',
                    };
                })
        },
        editStock(context, data) {
            return axios.put('stock/edit/' + data.id, data.stockCard)
                .then(res => {
                    context.commit('editStock', data);
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'ArrowUpCircleIcon',
                    };
                })
                .catch(() => {
                    return {
                        title: 'Stok bilgisi değiştirme hatası',
                        variant: 'danger',
                        icon: 'ArrowUpCircleIcon',
                    };
                });
        },
        saveNewStocks(context, data) {
            return axios.put('stock/edit/' + data.id, data)
                .then(res => {
                    if (res.data.status == 'success') {
                        alert('Başarılı')
                    } else {
                        alert('Kayıt başarısız')
                    }
                })
                .catch(() => ({
                    title: 'Ürün geldi silme hatası',
                    variant: 'danger',
                    icon: 'XCircleIcon',
                }))
        },
        deleteStockCard(context, id) {
            return axios.delete('/stock/' + id)
                .then(res => {
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'XCircleIcon',
                    }
                })
                .catch(() => {
                    alert('Stok kart silme hatası')
                    return {
                        title: "Stok kart silme hatası",
                        variant: "danger",
                        icon: 'XCircleIcon',
                    }
                })
        },
    }
}
